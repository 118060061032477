<template>
  <div>
    <template v-if="selected">
      <div :class="inputClass" @click="showInput" style="width: 100%;">{{ funSelected }}</div>
    </template>
    <template v-else>
      <input type="text" ref="input" :class="inputClass" :placeholder="placeholder" autoComplete="off" :value="inputData" @input="handleInput" @focus="showSelect" @blur="hideSelect">
    </template>
    <div class="select-box" v-show="show" style="margin-top: 10px;">
      <div class="select-item" v-for="(item) in matchOptions" :key="item" @mousedown="selectItem(item.id)">{{ item[label] }}</div>
    </div>
  </div>
</template>

<script>
import PinyinMatch from 'pinyin-match'

export default {
  name: "inputSelect",

  props: {
    label: String,
    options: {
      type: Array,
      default: null
    },
    inputClass: {
      type: String,
      default: 'form-control'
    },
    modelValue: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: '请选择'
    }
  },

  data() {
    return {
      matchOptions: [],
      inputData: null,
      selected: this.modelValue,
      show: false,
      top: 0,
      left: 0
    }
  },

  methods: {
    showInput() {
      this.inputData = null
      this.selected = false
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },

    showSelect() {
      this.matchOptions = this.options
      this.show = true
    },

    hideSelect() {
      this.show = false
      if (this.modelValue) {  
        this.selected = true
      }
      this.inputData = null
    },

    selectItem(id) {
      this.changeValue(id)
    },

    handleInput(e) {
      let filterList = []
      this.options.forEach(function(item) {
        if (e.target.value && PinyinMatch.match(item.name, e.target.value)) {
          filterList.push(item)
        }
      })

      this.matchOptions = filterList
      this.inputData = e.target.value
    },

    changeValue(id) {
      this.$emit('update:modelValue', id)
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue) {
        this.selected = true
      } else {
        this.selected = false
      }
    }
  },

  computed: {
    funSelected: {
      get() {
        let name
        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i].id == this.modelValue) {
            name = this.options[i][this.label]
          }
        }
        return name
      }
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
